<template>
  <div
    class='device_list pr'
    ref='deviceListBox'>
    <div class='table_box'>
      <el-table
        :data="deviceList"
        :empty-text="$t('lang.noData')"
        border
        ref='topicTable'
        row-key="peerId"
        style="width:100%"
        :expand-row-keys="expands"
        @row-click='expandDetail'
        @header-dragend='setBehavior'>
        <el-table-column
          type="expand"
          width="10">
          <template slot-scope="">
            <div
              class='video_detail'
              :class='{show:expands.length}'>
              <receiver-info
                class=""
                :testVal='expands'
                :checkSourceIdFlag='true'
                pn="hm"></receiver-info>
            </div>
          </template>
        </el-table-column>
        <!-- 列 -->
        <el-table-column
          v-for='(item, index) in titleArr'
          :key='index'
          :class-name='item.name'
          :label="$t(`lang.${item.name}`)"
          :width='item.width'>
          <template slot-scope="scope">
            <!-- 无法拖拽的列 -->
            <div
              :class="[dropCol[index]]"
              v-if='dropCol[index]=="thumbnailObj"'>
              <img
                class='thumbnail_img'
                :title="scope.row['peerId']"
                :src="scope.row['thumbnailObj']"
                alt=""
                :onerror="deafultImg">
            </div>
            <div
              :class="[dropCol[index]]"
              v-if='dropCol[index]=="source"'>
              <span
                class='status ellipsis'
                :class='[getStatusClass(scope.row)]'
                :title='scope.row.source'>
                {{ scope.row.source }}
              </span>
            </div>
            <div
              class='r_box'
              @click='prevent($event)'
              :class="[dropCol[index]]"
              v-if='dropCol[index]=="destination"'>
              <select-source-r
                :sourceItem="scope.row"
                :key="scope.row.peerId"
                :sourceListItemOps="scope.row"
                :hadSelectDevice="isSelectNewR[scope.row.peerId]"
                from='sourceList'
                type="T"
                @changeHadSelectDevice="changeHadSelectDevice"
                @getSelectInfo="getSelectInfo"></select-source-r>
            </div>
            <!-- 可拖拽并且只有显示功能的列 -->
            <div
              v-if='!"thumbnailObj,source,destination,battery,bookmark".includes(dropCol[index])'
              :class="[dropCol[index]]"
              :title='"thumbnailObj,source,destination,battery,bookmark,bitrate,latency".includes(dropCol[index])?"":scope.row[dropCol[index]]'
              v-html='scope.row[dropCol[index]]'>
            </div>
            <!-- bookmark -->
            <div
              :class="[dropCol[index]]"
              v-if='dropCol[index]=="bookmark"'>
              <span
                class="bookmark_name pointer test"
                :title='getBookmarkName(scope.row.userBookmarkDevice)'
                @click.stop='operationBookmarkBox(true, scope.row.userBookmarkDevice, scope.row.peerId)'>{{ getBookmarkName(scope.row.userBookmarkDevice) }}</span>
            </div>
            <!-- battery -->
            <div v-if='dropCol[index]=="battery"'>
              <div class=' f_left clearfix'>
                <div class="battery1-box f_left">
                  <span
                    class="batterySpan f_left greenColor"
                    v-if="isShowSpan1(scope.row.battery,scope.row)">N/A</span>
                  <div
                    class="battery-moudel f_left"
                    v-else
                    :title="scope.row.battery.BAT1VAL + '%'"
                    :class="[Number(scope.row.battery.BAT1VAL) <= 20 ? 'redBorder': '' ]">
                    <div
                      class="battery-dot"
                      :class="[Number(scope.row.battery.BAT1VAL) <= 20 ? 'redBorder': '' ]"></div>
                    <div
                      class="battery1-value"
                      :style="{width:`${scope.row.battery.BAT1VAL}%`}"></div>
                  </div>
                  <i
                    class="iconfont battery-charging top_12 f_left"
                    v-if="scope.row.battery && (scope.row.battery.BAT1 == '2' || scope.row.battery.BAT1 == '0')"></i>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          class-name='forbid_drag'
          width="123">
        </el-table-column>
      </el-table>
    </div>

    <div
      class='operation_col'
      :class='{shadow:hasShadow}'
      :style='{left:operationLeft}'>
      <div>
        <p class='f_left'>{{ $t(`lang.operation`) }}</p>
        <el-dropdown
          @command="changeTitle"
          :hide-on-click='false'>
          <span class="el-dropdown-link">
            <i class="el-icon-more pointer el-icon--right"></i>
          </span>
          <el-dropdown-menu
            slot="dropdown"
            class='devicelist_title_list'>
            <el-dropdown-item
              :command='item'
              v-for='(item, index) in allTitle'
              :key='index'>
              <el-checkbox
                :label="$t(`lang.${item}`)"
                v-model="check[item]"></el-checkbox>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class='device_box'
        v-for='(item, index) in deviceList'
        :key='index'>
        <div
          class='device_btn'
          @click="prevent($event)">
          <!--引入Take组件-->
          <video-button
            :style="{'margin-left':'10px','float':'right','width':'73px'}"
            :sourceItem='item'
            :hadSelectDevice="isSelectNewR[item.peerId]"
            flag="T"
            parentComponent='sourceList'
            :deviceList="changeRArr[item.peerId]"
            :key="item.peerId"
            @changeHadSelectDevice="changeHadSelectDevice"
            @stopRState="stopRState">
          </video-button>
        </div>
        <div
          class='expend_box'
          v-if='expands[0]==item.peerId'></div>
      </div>
    </div>

    <!-- add bookmark -->
    <add-bookmark
      :visible='isShow'
      @closeBox='operationBookmarkBox'
      :lastBookmark='updateBookmarkObj.userBookmarkDevice'
      :deviceId='updateBookmarkObj.peerId'
      :deviceObj='{id:updateBookmarkObj.peerId,type:$route.path.includes("nhm")?"Home":"MySource"}'>
    </add-bookmark>

    <!-- videoControl -->
    <video-control
      v-if="showVideoControl"
      :rid="currentDevice.livePeerId"
      :currentInfo="currentDevice"
      :currentSocketData="currentSocketData"
      :listItemOps="currentDevice"
      :key="currentDevice.peerId + 'videoBox'"
      :close="closeVideoBox"
      title="sourceControlTitle">
    </video-control>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { SHOWDEVICEIMG } from '@/assets/js/filters'
import { mapState } from 'vuex'
// import showBattery from '@/assets/js/common/handleBattery'
import receiverInfo from '@/views/MySource/receiverInfo'
import AddBookmark from '@/components/addBookmark'
import videoControl from '@/views/Home/videoControl'
import SelectSourceR from '@/components/SelectSourceR'
// import { MySource } from '@/assets/api/mySource'
import videoButton from '@/components/VideoButton'
import Bus from '@/assets/js/vueBus'
import { handleSource } from '@/assets/js/shareCommon.js'
import Outil from '@/assets/js/utils.js'

import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import { mySourceAnalyze } from '@/assets/js/googleAnalyze/mySource.js'

export default {
  props: {
    data: Object, // 列表内的实时信息
    imgUrlTime: Object, // 缩略图对象
    initDeviceList: Array, // 列表数据
    page: String // 属于哪个页面
  },
  components: {
    receiverInfo,
    AddBookmark,
    videoControl,
    SelectSourceR,
    videoButton
  },
  data () {
    return {
      dropCol: ['thumbnailObj', 'source', 'destination', 'liveBitrate', 'bitrate', 'latency', 'format', 'battery', 'bookmark', 'typeVal', 'version', 'location'],
      allTitle: ['bitrate', 'latency', 'format', 'battery', 'bookmark', 'typeVal', 'version', 'location'],
      titleArr: [
        {
          name: 'thumbnailObj',
          width: 180
        },
        {
          name: 'source',
          width: 150
        },
        {
          name: 'destination',
          width: 180
        },
        {
          name: 'liveBitrate',
          width: 150
        },
        {
          name: 'bitrate',
          width: 150
        },
        {
          name: 'latency',
          width: 150
        },
        {
          name: 'format',
          width: 150
        },
        {
          name: 'battery',
          width: 130
        },
        {
          name: 'bookmark',
          width: 130
        },
        {
          name: 'typeVal',
          width: 130
        },
        {
          name: 'version',
          width: 130
        },
        {
          name: 'location',
          width: 130
        }
      ],
      lastTitleArr: [],
      removeTitles: [],
      deviceList: [],
      expands: [],
      detailData: {},
      isShow: {
        bookmark: false
      },
      updateBookmarkObj: {
        userBookmarkDevice: null,
        peerId: ''
      },
      checkTitleList: [],
      check: {
        bitrate: true,
        latency: true,
        format: true,
        battery: true,
        bookmark: true,
        typeVal: true,
        version: true,
        location: true
      },
      timer: null,
      currentDevice: {},
      showVideoControl: false,
      isSelectNewR: [], // 是否重新选择了R
      deviceDetailId: '',
      hasStopSelectR: false,
      changeRArr: [],
      receiverObject: {},
      sourceObject: {},
      loadingTimer: null,
      showStopping: [],
      locationInfo: [],
      deafultImg: 'this.src="' + require('@/assets/img/logo_thumbnail.png') + '"',
      liveRids: [], // home页面发送websocket的liverid数组
      routerPath: '', // 跳转路由的地址
      initCurrentInfoSocket: true,
      currentSocketData: null,
      setBiteDealyFlag: true, // 单次设置防止重复点击
      operationLeft: null,
      hasShadow: false, // 右侧固定列添加阴影
      paramTimer: null,
      lang: ''
    }
  },
  computed: {
    ...mapState({
      StateObj: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    }),
    // quality and error
    listenQualityErrData () {
      return this.$store.state.common.deviceListReceiverDataData
    },
    // format and type
    listenFormatData () {
      return this.$store.state.common.deviceListCurrentRInfoData
    },
    // bitrate and delay
    listenBitDelayData () {
      return this.$store.state.common.deviceListSetInfoData
    }

  },
  watch: {
    'data.allSourceData' (val) { // 更新device列表
      if (!this.deviceList.length) return
      this.liveRids = []
      this.deviceList.map((v, i) => {
        const newData = val[v.peerId]
        if (this.currentDevice && this.currentDevice.peerId == v.peerId) {
          this.currentDevice = val[v.peerId]
          this.currentSocketData = handleSource(newData.peerId, newData.livePeerId, newData && newData.status == '2', val[v.peerId])
        }
        if (!newData) return
        let lastData = JSON.parse(JSON.stringify(v))
        if (newData.status == '2') this.liveRids.push(newData.livePeerId)
        lastData.status = newData.status
        lastData.gridLivePeerId = newData?.gridLivePeerId || ''
        lastData.gridLivePeerName = newData?.gridLivePeerName || ''
        if (lastData.type == 'X') {
          lastData.location = this.locationInfo[lastData.peerId.slice(0, 16)] && this.locationInfo[lastData.peerId.slice(0, 16)].address
        } else {
          lastData.location = this.locationInfo[lastData.peerId] && this.locationInfo[lastData.peerId].address
        }

        if (!this.isSelectNewR[lastData.peerId]) {
          lastData.btnStatus = newData.status == 2 ? 'stop' : 'goLive'
          lastData.livePeerName = newData?.livePeerName || ''
          lastData.destination = lastData.livePeerName
          lastData.livePeerId = newData?.livePeerId || ''
          if (newData?.lastLiveName) lastData.lastLiveName = newData.lastLiveName
        } else {
          lastData.btnStatus = 'goLive'
        }
        let bitData = {}
        if (this.routerPath.indexOf('nhm') > 0) {
          bitData = {
            CurrentSetting: {
              Bitrate: lastData.BitrateNum ? lastData.BitrateNum : 0,
              Delay: lastData.livelatency ? lastData.livelatency : 0
              // liveBit:lastData.BitrateNum?lastData.BitrateNum:0+'kbps'
            },
            InternalLiveSetting: {
              StrVideoMode: lastData.StrVideoMode
            },
            CurrentTCanSetVideoMode: lastData.CurrentTCanSetVideoMode,
            CurrentTCanSetDelay: lastData.CurrentTCanSetDelay,
            CurrentTCanSetDelayConfigurable: lastData.CurrentTCanSetDelayConfigurable,
            CurrentTCanSetBitrate: lastData.CurrentTCanSetBitrate,
            formatInfo: {
              CurrentTFormat: {
                ReadableName: lastData.ReadableName
              },
              CurrentTPackBatteryRaw: lastData.CurrentTPackBatteryRaw
            }
          }
          if (lastData.status == 2 && this.detailData[v.livePeerId] && this.detailData[v.livePeerId].qualityErrInfo) {
            const nowBitrate = this.detailData[v.livePeerId].qualityErrInfo.QualityMeasureOnGUI
            lastData.liveBitrate = `${nowBitrate} kbps`
          }
        } else {
          bitData = this.detailData[v.livePeerId] && this.detailData[v.livePeerId].bitDelayInfo
          if (lastData.status == 2 && bitData && this.detailData[v.livePeerId].qualityErrInfo) {
            const nowBitrate = this.detailData[v.livePeerId].qualityErrInfo.QualityMeasureOnGUI
            lastData.liveBitrate = `${nowBitrate} kbps`
          }
        }
        if (lastData.status == 2 && bitData) {
          const currentSetting = bitData.CurrentSetting
          const liveBitrate = `${currentSetting && currentSetting.Bitrate}`
          const liveDelay = `${currentSetting && currentSetting.Delay}`
          // bitrate
          const websockeInfo = bitData
          // let bitFeature = '' // 是否设置cbr,vbr的feature
          const bitInputFeature = websockeInfo.CurrentTCanSetBitrate ? 'pointer-events:auto' : 'pointer-events:none' // 是否设置比特率的输入框里feature
          let cbrStyle = '' // cbr
          let vbrStyle = '' // vbr
          // let set
          let extNotClickBiter = 'float: left;font-size: 13px;pointer-events:auto'
          let extNotClickDelay = 'float: left;font-size: 13px;pointer-events:auto'
          if (lastData.type.toLowerCase() == 'ext') {
            extNotClickBiter = 'float: left;font-size: 13px;pointer-events:none'
            extNotClickDelay = 'float: left;font-size: 13px;pointer-events:none'
          }
          if (lastData.type.toLowerCase() == 'x') {
            extNotClickBiter = 'float: left;font-size: 13px;pointer-events:none'
          }
          if (websockeInfo.InternalLiveSetting.StrVideoMode == 'Vbr') {
            if (websockeInfo.CurrentTCanSetVideoMode) {
              vbrStyle = 'color: #33ab4f;pointer-events:auto'
              cbrStyle = 'color:#fff;pointer-events:auto'
            } else {
              vbrStyle = 'color: #33ab4f;pointer-events:none'
              cbrStyle = 'color:#777;pointer-events:none'
            }
          } else {
            if (websockeInfo.CurrentTCanSetVideoMode) {
              vbrStyle = 'color:#fff;pointer-events:auto'
              cbrStyle = 'color:#33ab4f;pointer-events:auto'
            } else {
              vbrStyle = 'color:#777;pointer-events:none'
              cbrStyle = 'color:#33ab4f;pointer-events:none'
            }
          }
          lastData.bitrate = `<div class='bitrate_box down_box pr'>
                            <span class='down_triangle' data-index='${i}' style="${extNotClickBiter}" title="${liveBitrate}">${liveBitrate} Kbps</span>
                        <div class='edit_box'>
                            <p class="seleType">
                                <span class="vbr"  style="${vbrStyle}" data-id="${lastData.livePeerId}">VBR</span>|
                                <span class="cbr"  style="${cbrStyle}" data-id="${lastData.livePeerId}">CBR</span>
                            </p>
                            <p class="seleType">
                                <input class='edit_bit' data-biter="${liveBitrate}" data-id="${lastData.livePeerId}"  data-delay="${liveDelay}" style="${bitInputFeature}" />
                                <span>Kbps</span>
                            </p>
                        </div>
                    </div>`

          const setDelayFeature = websockeInfo.CurrentTCanSetDelay ? 'pointer-events:auto' : 'pointer-events:none'
          // 能否输入delay的框
          let dmcStyle = ''
          let fixStyle = ''
          if (websockeInfo.CurrentTCanSetDelayConfigurable) {
            if (websockeInfo.CurrentTCanSetDelay) {
              fixStyle = 'color: #33ab4f;pointer-events:auto'
              dmcStyle = 'color:#fff;pointer-events:auto'
            } else {
              fixStyle = 'color: #fff;pointer-events:auto'
              dmcStyle = 'color:#33ab4f;pointer-events:auto'
            }
          } else {
            if (websockeInfo.CurrentTCanSetDelay) {
              fixStyle = 'color: #33ab4f;pointer-events:none'
              dmcStyle = 'color:#777;pointer-events:none'
            } else {
              fixStyle = 'color: #777;pointer-events:none'
              dmcStyle = 'color:#33ab4f;pointer-events:none'
            }
          }
          // lastData.latency = this.getDelay((currentSetting && currentSetting.Delay / 1000).toFixed(1), this.detailData[v.livePeerId].bitDelayInfo.CurrentTCanSetDelay)
          lastData.latency = `<div class='bitrate_box down_box pr'>
                            <span class='down_triangle' data-index='${i}' style="${extNotClickDelay}" title="${currentSetting.Delay / 1000} s">${bitData.CurrentSetting.Delay / 1000}&nbsp;${this.$t('lang.second')}</span>
                        <div class='edit_box'>
                            <p class="seleType">
                                <span class="dmc" style="${dmcStyle}" data-id="${lastData.livePeerId}">${this.$t('lang.Dynamic')}</span>|
                                <span class="fixd" style="${fixStyle}"  data-id="${lastData.livePeerId}">${this.$t('lang.Fixed')}</span>
                            </p>
                            <p class="seleType">
                                <input class='edit_delay' data-biter="${liveBitrate}" data-id="${lastData.livePeerId}"  data-delay="${liveDelay}" style="${setDelayFeature}" />
                                <span>${this.$t('lang.second')}</span>
                            </p>
                        </div>
                    </div>`
          if (this.routerPath.indexOf('nhm') > 0 && newData.status == 2) {
            lastData.format = websockeInfo.formatInfo.CurrentTFormat.ReadableName
            // lastData.battery = showBattery(websockeInfo.formatInfo.CurrentTPackBatteryRaw, lastData.platform)
            lastData.battery = websockeInfo.formatInfo.CurrentTPackBatteryRaw
          } else {
            if (newData.status == 2) { // 正在live的，从R上获取format和电池信息
              lastData.format = this.detailData[v.livePeerId].formatInfo && this.detailData[v.livePeerId].formatInfo.CurrentTFormat.ReadableName || ''
              lastData.battery = this.detailData[v.livePeerId].formatInfo && this.detailData[v.livePeerId].formatInfo.CurrentTPackBatteryRaw || []
            }
          }
        } else {
          lastData.bitrate = ''
          lastData.latency = ''
          lastData.liveBitrate = ''
        }
        if (newData.status == 0) {
          lastData.format = ''
          lastData.battery = []
        }
        this.$set(this.deviceList, i, lastData)
        this.$nextTick(() => {
          this.removeBitrEvent()
          this.removeSelectSpanEvent()
          this.removeEnterBitrEvent() // 解绑
          this.removefoucsBitrEvent()
          this.removeEnterDelayEvent()
          this.removefoucsDelayEvent()
          this.removeEditBoxEvent()
          this.addBitrEvent()
          this.addSelectSpanEvent()
          this.enterBitrEvent() // 绑定
          this.foucsBitrEvent()
          this.enterDelayEvent()
          this.foucsDelayEvent()
          this.addEditBoxEvent()
        })
        // 下拉时将数据传递给receiver info
        if (this.expands.length && this.expands[0].toLowerCase() == newData.peerId.toLowerCase()) {
          const dataObj = {
            rid: newData.livePeerId,
            sourceItem: newData,
            isDown: true
          }
          Bus.$emit('showReceiverInfo', dataObj)
        }
      })
      if (this.routerPath.indexOf('nhm') > 0) {
        if (this.liveRids.length == 0) {
          // stop
          this.stopupdateParams()
          this.initCurrentInfoSocket = true
          return
        }
        if (this.initCurrentInfoSocket) { // update param
          this.createMsgWebsocket()
          this.updateParams()
          this.initCurrentInfoSocket = false
        } else {
          this.updateParams()
        }
      }
    },
    // 获取列表数据
    initDeviceList (val) {
      this.initDataList(val)
    },
    // 更新缩略图
    imgUrlTime: {
      handler (val) {
        this.deviceList.map((v, i) => {
          let item = JSON.parse(JSON.stringify(v))
          if (item.status == 2 && item.type != 'X') {
            if (this.imgUrlTime.current[item.livePeerId] == this.imgUrlTime.last[item.livePeerId]) return
          } else {
            if ((item.type == 'Ext' || item.type == 'YouTube' || item.type == 'SRT' || item.type == 'SMPTE2022' || item.type == 'FILE') && item.status == 1) {
              item.thumbnailObj = SHOWDEVICEIMG(v)
            } else {
              if (this.imgUrlTime.current[item.peerId] == this.imgUrlTime.last[item.peerId] && item.status == 1) return
            }
          }
          item.thumbnailObj = SHOWDEVICEIMG(v)
          this.$set(this.deviceList, i, item)
        })
      },
      deep: true
    },
    // quality and error
    listenQualityErrData (val) {
      val.map((v, i) => {
        if (!this.detailData[v.rid]) this.detailData[v.rid] = {}
        this.detailData[v.rid].qualityErrInfo = JSON.parse(v.data)
      })
    },
    // format and type
    listenFormatData (val) {
      val.map((v, i) => {
        if (!this.detailData[v.rid]) this.detailData[v.rid] = {}
        this.detailData[v.rid].formatInfo = JSON.parse(v.data)
      })
    },
    // bitrate and delay
    listenBitDelayData (val) {
      val.map((v, i) => {
        if (!this.detailData[v.rid]) this.detailData[v.rid] = {}
        this.detailData[v.rid].bitDelayInfo = JSON.parse(v.data)
      })
    }
  },
  async created () {
    this.lang = window.localStorage.getItem('lang')
    this.initDataList(this.initDeviceList)
    window.addEventListener('resize', this.handleResize)
    await this.getBehavior()
    await this.getOperationPosition()
    this.isBoxShadow()
    Bus.$on('openRVideoDetail', data => {
      if (data) { // 打开R详情框
        this.stopupdateParams()
        return
      }
      this.createMsgWebsocket()
      this.updateParams()
    })
    Bus.$on('changeListType', () => {
      this.initDeviceList = JSON.parse(JSON.stringify(this.initDeviceList))
      this.initDataList(this.initDeviceList)
    })
  },
  mounted () {
    this.columnDrop()
    this.routerPath = this.$route.path
  },
  methods: {
    isShowSpan1 (i, a) {
      if (i == undefined || !i || (a && a.type != 'T')) return true
      if (i && i.BAT1) {
        const bat1 = i.BAT1
        if (bat1 == -1 || bat1 == null || bat1 == '') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    // 窗口大小调整
    handleResize () {
      this.getOperationPosition()
    },
    // 获取固定操作栏位置
    async getOperationPosition () {
      await setTimeout(() => {
        const tableWidth = $('.el-table__header-wrapper table').width()
        if (this.$refs.deviceListBox) {
          const screenWidth = this.$refs.deviceListBox.offsetWidth
          this.operationLeft = screenWidth - tableWidth > 123 ? `${tableWidth}px` : `${screenWidth - 123}px`
        }
      }, 100)
    },
    createMsgWebsocket () {
      this.stopupdateParams()
      this.sendMsgWebsocket('CurrentRInfo', '2152857600', '102', 1000) // 获取type and format信息
      this.sendMsgWebsocket('SetInfo', '2152866048', '101', 1000) // 获取bitrate and delay信息
    },
    sendMsgWebsocket (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.liveRids.join(',')
        },
        time: time || 5000,
        key: typeVal || type,
        success: res => {
          this.updateDevice(res)
        }
      })
    },
    updateParams () {
      this.updateSocketParams('currentRInfo', '2152857600', '102')
      this.updateSocketParams('setInfo', '2152866048', '101')
    },
    stopupdateParams () { // 停止获取数据
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['currentRInfo', 'setInfo']) // 停止websocket
      }
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      const message = {
        categoryId,
        operationType,
        rid: this.liveRids.join(',')
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
    },
    // 更新参数
    updateSourceParam () {
      if (this.paramTimer) clearInterval(this.paramTimer)
      this.paramTimer = setInterval(() => {
        const tPeerIdObjs = this.deviceList.filter(v => {
          return v.type.toLowerCase() == 't' && v.status != 0 && v.status != 2
        })
        let tPeerIds = []
        tPeerIdObjs.map(v => {
          tPeerIds.push(v.peerId)
        })
        if (!tPeerIds.length) {
          if (this.$createWebsocket.pageRequest) {
            this.$createWebsocket.pageRequest('stop', 'detailInfo') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
            return
          }
        } else {
          if (!this.pageRequest.sendMsgs || this.pageRequest.sendMsgs && !this.pageRequest.sendMsgs.detailInfo) {
            clearInterval(this.paramTimer)
            this.getOnlineData()
          }
        }
        if (this.pageRequest.sendMsgs && this.pageRequest.sendMsgs.detailInfo) {
          const currentPeerId = this.pageRequest.sendMsgs.detailInfo.message.data.peerIds
          for (let i = 0; i < tPeerIds.length; i++) {
            if (tPeerIds[i] == currentPeerId) {
              this.pageRequest.sendMsgs.detailInfo.message.data.peerIds = tPeerIds[i + 1] ? tPeerIds[i + 1] : tPeerIds[0]
              break
            }
          }
        }
      }, 2000)
    },
    // 获取在线的数据
    getOnlineData () {
      const tPeerIdObjs = this.deviceList.filter(v => {
        return v.type.toLowerCase() == 't' && v.status != 0 && v.status != 2
      })
      let tPeerIds = []
      tPeerIdObjs.map(v => {
        tPeerIds.push(v.peerId)
      })
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'detailInfo') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest && this.pageRequest.send({
        message: {
          type: 'detailInfo',
          module: 'detailInfo',
          // 'data': {'peerIds': tPeerIds.join(',')}
          data: { peerIds: tPeerIds[0] }
        },
        time: 1000,
        key: 'detailInfo',
        success: data => {
          data = JSON.parse(data.result)[0]
          this.deviceList.map(v => {
            if (v.peerId == data.peerId) {
              // v.battery = showBattery(data.CurrentTPackBatteryRaw, v.platform)
              v.battery = JSON.parse(data.CurrentTPackBatteryRaw)
              const mediaData = data.media ? data.media.format.split(',') : []
              v.format = mediaData ? this.$options.filters.TRANSFORMFORMAT(mediaData[2]) : 'N/A'
              if (v.type.toLowerCase() == 't' && v.status == 3) {
                v.format = 'N/A'
              }
            }
          })
        }
      })
      this.updateSourceParam(tPeerIds)
    },
    prevent (e) {
      e.stopPropagation()
    },
    // 更改title显示
    changeTitle (key) {
      if (this.timer) clearTimeout(this.timer)
      this.titleArr = []
      this.dropCol = []
      this.timer = setTimeout(() => {
        this.titleArr = this.lastTitleArr
        const boxWidth = this.$refs.deviceListBox.offsetWidth
        const totleWidth = boxWidth - (20 + 123) // 下拉列:20px, 固定栏:123px
        // let width = 0
        let addIndex
        this.check[key] && this.removeTitles.map((v, i) => { // 添加
          if (v && v.name == key) {
            addIndex = i
          }
        })
        let index
        let newWidth = this.check[key] ? Math.ceil(totleWidth / (this.titleArr.length + 1)) : Math.ceil(totleWidth / (this.titleArr.length - 1)) // 每列的宽度
        newWidth = newWidth < 70 ? 70 : newWidth
        this.titleArr.map((v, i) => {
          v.width = newWidth
          v.width = v.name == 'thumbnailObj' && newWidth < 150 ? 150 : newWidth
          if (v && v.name == key) { // 找出当前列的下标，移除时可使用
            index = i
            v.index = i
            this.check[key] && this.dropCol.push(v.name)
          } else {
            this.dropCol.push(v.name)
          }
        })
        !this.check[key] && this.removeTitles.push(this.titleArr.splice(index, 1)[0])
        if (this.check[key]) {
          this.removeTitles[addIndex].width = newWidth
        }
        this.check[key] && this.dropCol.splice(this.removeTitles[addIndex].index, 0, this.removeTitles[addIndex].name) // 添加时插入元素
        this.check[key] && this.titleArr.splice(this.removeTitles[addIndex].index, 0, this.removeTitles[addIndex]) // 添加时插入元素
        this.check[key] && this.removeTitles.splice(addIndex, 1)
        this.lastTitleArr = JSON.parse(JSON.stringify(this.titleArr))
        // 清空上次选中的R
        this.changeRArr = []
        this.isSelectNewR = []
        this.setBehavior()
        this.getOperationPosition()
      })
      // console.log(key,this.$route.path)
      if (this.$route.path.indexOf('nhm') > -1) {
        this.goolgeAlativite('home', key)
      } else {
        this.goolgeAlativite('mysource', key)
      }
    },
    goolgeAlativite (x, y) { // 谷歌统计筛选框
      if (x == 'home') {
        switch (y) {
          case 'bitrate':
            newHomeAnalyze('newHomeTableBitrate', this.$route.path) // 谷歌统计
            break
          case 'latency':
            newHomeAnalyze('newHomeTableLatency', this.$route.path) // 谷歌统计
            break
          case 'format':
            newHomeAnalyze('newHomeTableFormat', this.$route.path) // 谷歌统计
            break
          case 'battery':
            newHomeAnalyze('newHomeTableBattery', this.$route.path) // 谷歌统计
            break
          case 'bookmark':
            newHomeAnalyze('newHomeTableBookmark', this.$route.path) // 谷歌统计
            break
          case 'typeVal':
            newHomeAnalyze('newHomeTableTypeVal', this.$route.path) // 谷歌统计
            break
          case 'version':
            newHomeAnalyze('newHomeTableVersion', this.$route.path) // 谷歌统计
            break
          case 'location':
            newHomeAnalyze('newHomeTableLocation', this.$route.path) // 谷歌统计
            break
        }
      } else {
        switch (y) {
          case 'bitrate':
            mySourceAnalyze('mySourceTableBitrate', this.$route.path) // 谷歌统计
            break
          case 'latency':
            mySourceAnalyze('mySourceTableLatency', this.$route.path) // 谷歌统计
            break
          case 'format':
            mySourceAnalyze('mySourceTableFormat', this.$route.path) // 谷歌统计
            break
          case 'battery':
            mySourceAnalyze('mySourceTableBattery', this.$route.path) // 谷歌统计
            break
          case 'bookmark':
            mySourceAnalyze('mySourceTableBookmark', this.$route.path) // 谷歌统计
            break
          case 'typeVal':
            mySourceAnalyze('mySourceTableTypeVal', this.$route.path) // 谷歌统计
            break
          case 'version':
            mySourceAnalyze('mySourceTableVersion', this.$route.path) // 谷歌统计
            break
          case 'location':
            mySourceAnalyze('mySourceTableLocation', this.$route.path) // 谷歌统计
            break
        }
      }
    },
    addBitrEvent () {
      const bitrDown = document.querySelectorAll('table .down_triangle')
      bitrDown.forEach(v => {
        v.addEventListener('click', this.listenerBiter, false)
      })
    },
    removeBitrEvent () {
      const nodeList = document.querySelectorAll('table .down_triangle')
      nodeList.forEach(v => {
        v.removeEventListener('click', this.listenerBiter)
      })
    },
    listenerBiter (e) {
      e.stopPropagation()
      this.setBitrInfo(e.target)
    },
    addEditBoxEvent () {
      const bitrDown = document.querySelectorAll('table .edit_box')
      bitrDown.forEach(v => {
        v.addEventListener('click', this.listenerEditBoxEvent, false)
      })
    },
    removeEditBoxEvent () {
      const nodeList = document.querySelectorAll('table .edit_box')
      nodeList.forEach(v => {
        v.removeEventListener('click', this.listenerEditBoxEvent)
      })
    },
    listenerEditBoxEvent (e) {
      e.stopPropagation()
    },
    setBitrInfo (event) {
      if (event.innerHTML.indexOf('nbsp') > -1) {
        if (this.$route.path.indexOf('nhm') > -1) {
          newHomeAnalyze('newHomeTableDelayShow', this.$route.path)
        } else {
          mySourceAnalyze('mySourceTableDelayShow', this.$route.path)
        }
      } else {
        if (this.$route.path.indexOf('nhm') > -1) {
          newHomeAnalyze('newHomeTableBiterShow', this.$route.path)
        } else {
          mySourceAnalyze('mySourceTableBiterShow', this.$route.path)
        }
      }

      if ($($(event)[0]).parent().find('.edit_box').css('display') == 'none') {
        const index = event.dataset.index
        $(event).parent().removeClass('right_side')
        $(event).parent().removeClass('top_side')
        if (this.deviceList.length - 1 == index) {
          $(event).parent().addClass(`${this.deviceList.length == 1 ? 'right_side' : 'top_side'}`)
        }
        $('.edit_box').hide()
        $($(event)[0]).parent().find('.edit_box').show()
      } else {
        $($(event)[0]).parent().find('.edit_box').hide()
        $(event).parent().removeClass('right_side')
        $(event).parent().removeClass('top_side')
      }
    },
    addSelectSpanEvent () {
      const cbrDown = document.querySelectorAll('table .edit_box .cbr')
      const vbrDown = document.querySelectorAll('table .edit_box .vbr')
      const dmcDown = document.querySelectorAll('table .edit_box .dmc')
      const fixedDown = document.querySelectorAll('table .edit_box .fixd')
      cbrDown.forEach(v => {
        v.addEventListener('click', this.listenerSelectSpan, false)
      })
      vbrDown.forEach(v => {
        v.addEventListener('click', this.listenerSelectSpan, false)
      })
      dmcDown.forEach(v => {
        v.addEventListener('click', this.listenerSelectSpan, false)
      })
      fixedDown.forEach(v => {
        v.addEventListener('click', this.listenerSelectSpan, false)
      })
    },
    removeSelectSpanEvent () {
      const vbrDown = document.querySelectorAll('table .edit_box .vbr')
      const cbrDown = document.querySelectorAll('table .edit_box .cbr')
      const dmcDown = document.querySelectorAll('table .edit_box .dmc')
      const fixedDown = document.querySelectorAll('table .edit_box .fixd')
      vbrDown.forEach(v => {
        v.removeEventListener('click', this.listenerSelectSpan)
      })
      cbrDown.forEach(v => {
        v.removeEventListener('click', this.listenerSelectSpan)
      })
      dmcDown.forEach(v => {
        v.removeEventListener('click', this.listenerSelectSpan)
      })
      fixedDown.forEach(v => {
        v.removeEventListener('click', this.listenerSelectSpan)
      })
    },
    listenerSelectSpan (e) {
      e.stopPropagation()
      this.setBitrSpan(e)
    },
    setBitrSpan (event) {
      // console.log($($(event.target)[0]).attr('data-id'))
      const id = $($(event.target)[0]).attr('data-id')
      const dom = $($(event.target)[0])
      let mode = ''
      if (dom.css('color') == 'rgb(255, 255, 255)') {
        if ($(event.target)[0].className == 'vbr') {
          mode = 'vbr'
          this.setMode(id, mode, dom)
        } else if ($(event.target)[0].className == 'cbr') {
          mode = 'cbr'
          this.setMode(id, mode, dom)
        } else if ($(event.target)[0].className == 'dmc') {
          mode = 'false'
          this.setDymandfixed(id, mode, dom, 'Dynamic')
        } else if ($(event.target)[0].className == 'fixd') {
          mode = 'true'
          this.setDymandfixed(id, mode, dom, 'Fixed')
        }
      }
    },
    enterBitrEvent () {
      const bitrDown = document.querySelectorAll('table .edit_box .edit_bit')
      bitrDown.forEach(v => {
        v.addEventListener('keydown', this.listenerEnterBitr, false)
      })
    },
    removeEnterBitrEvent () {
      const nodeList = document.querySelectorAll('table .edit_box .edit_bit')
      nodeList.forEach(v => {
        v.removeEventListener('keydown', this.listenerEnterBitr)
      })
    },
    listenerEnterBitr (e) {
      e.stopPropagation()
      this.setEnterBitr(e)
    },
    setEnterBitr (e) {
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableSetBiterVal', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableSetBiterVal', this.$route.path)
      }
      const bitr = $($(event.target)[0]).val()
      const id = $($(event.target)[0]).attr('data-id')
      const delay = $($(event.target)[0]).attr('data-delay')
      if (e.keyCode == 13) {
        if (Number(bitr) >= 100 && Number(bitr) <= 10240) {
          this.setDelayandBitr(bitr, id, delay)
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('lang.bitrateInput')
          })
        }
      }
    },
    enterDelayEvent () {
      const bitrDown = document.querySelectorAll('table .edit_box .edit_delay')
      bitrDown.forEach(v => {
        v.addEventListener('keydown', this.listenerEnterDelay, false)
      })
    },
    removeEnterDelayEvent () {
      const nodeList = document.querySelectorAll('table .edit_box .edit_delay')
      nodeList.forEach(v => {
        v.removeEventListener('keydown', this.listenerEnterDelay)
      })
    },
    listenerEnterDelay (e) {
      e.stopPropagation()
      this.setEnterDelay(e)
    },
    setEnterDelay (e) {
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableSetDelayVal', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableSetDelayVal', this.$route.path)
      }
      const bitr = $($(event.target)[0]).attr('data-biter')
      const id = $($(event.target)[0]).attr('data-id')
      const delay = $($(event.target)[0]).val()
      if (e.keyCode == 13) {
        if (Number(delay) >= 0.5 && Number(delay) <= 50) {
          this.setDelayandBitr(bitr, id, delay * 1000)
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('lang.delayInputRange')
          })
        }
      }
    },
    foucsBitrEvent () {
      const bitrDown = document.querySelectorAll('table .edit_box .edit_bit')
      bitrDown.forEach(v => {
        v.addEventListener('click', this.listenerfoucsBitr, false)
      })
    },
    removefoucsBitrEvent () {
      const nodeList = document.querySelectorAll('table .edit_box .edit_bit')
      nodeList.forEach(v => {
        v.removeEventListener('click', this.listenerfoucsBitr)
      })
    },
    listenerfoucsBitr (e) {
      e.stopPropagation()
      this.preventBitr(e.target.dataset)
    },
    preventBitr (e) {
      // console.log($(event.target))
    },
    foucsDelayEvent () {
      const bitrDown = document.querySelectorAll('table .edit_box .edit_delay')
      bitrDown.forEach(v => {
        v.addEventListener('click', this.listenerfoucsDelay, false)
      })
    },
    removefoucsDelayEvent () {
      const nodeList = document.querySelectorAll('table .edit_box .edit_delay')
      nodeList.forEach(v => {
        v.removeEventListener('click', this.listenerfoucsDelay)
      })
    },
    listenerfoucsDelay (e) {
      e.stopPropagation()
      this.preventDelay(e.target.dataset)
    },
    preventDelay (e) {
      // console.log($(event.target))
    },
    setMode (id, mode, dom) {
      this.$confirm(this.$t('lang.confirmModeChange', { mode: mode.toUpperCase() }), '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        const dataStr = {
          rid: id.toLowerCase(),
          data: '{"VideoMode":"' + mode + '"}',
          categoryId: '2152866048',
          operationType: '201'
        }
        this.axios.post('/ccp/tvucc-rws/receiver/command/setCbrVbr', dataStr)
          .then(res => {
            if (res.data.errorCode == '0x0') {
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
              if (mode == 'vbr') {
                dom.parent().find('.vbr').css('color', '#33ab4f')
                dom.parent().find('.cbr').css('color', '#fff')
              } else {
                dom.parent().find('.cbr').css('color', '#33ab4f')
                dom.parent().find('.vbr').css('color', '#fff')
              }
            } else {
              Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
            }
          }).catch(() => {})
      }).catch(() => {})
      if (this.$route.path.indexOf('nhm') > -1) {
        if (mode == 'vbr') {
          newHomeAnalyze('newHomeTableSetBiterVbr', this.$route.path)
        } else {
          newHomeAnalyze('newHomeTableSetBiterCbr', this.$route.path)
        }
      } else {
        if (mode == 'vbr') {
          mySourceAnalyze('mySourceTableSetBiterVbr', this.$route.path)
        } else {
          mySourceAnalyze('mySourceTableSetBiterCbr', this.$route.path)
        }
      }
    },
    setDymandfixed (id, mode, dom, key) {
      this.$confirm(this.$t('lang.confirmModeChange', { mode: this.$t(`lang.${key}`) }), '', {
        confirmButtonText: this.$t('lang.confirmBtn'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        const dataStr = {
          rid: id.toLowerCase(),
          data: '{"CanSetDelay":"' + mode + '"}',
          OperationType: '204',
          categoryId: '2152866048'
        }
        this.axios.post('/ccp/tvucc-rws/receiver/command/setVideoDynamic', dataStr)
          .then(res => {
            if (res.data.errorCode == '0x0') {
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
              if (mode) {
                dom.parent().find('.fixd').css('color', '#33ab4f')
                dom.parent().find('.dmc').css('color', '#fff')
              } else {
                dom.parent().find('.dmc').css('color', '#33ab4f')
                dom.parent().find('.fixd').css('color', '#fff')
              }
            } else {
              Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
            }
          }).catch(() => {})
      }).catch(() => {})
      if (this.$route.path.indexOf('nhm') > -1) {
        if (mode) {
          newHomeAnalyze('newHomeTableSetDelayFixed', this.$route.path)
        } else {
          newHomeAnalyze('newHomeTableSetDelayDynamic', this.$route.path)
        }
      } else {
        if (mode) {
          mySourceAnalyze('mySourceTableSetDelayFixed', this.$route.path)
        } else {
          mySourceAnalyze('mySourceTableSetDelayDynamic', this.$route.path)
        }
      }
    },
    setDelayandBitr (bit, id, delay) {
      bit = Number(bit).toFixed(0)
      delay = Math.floor(delay)
      const params = {
        rid: id.toLowerCase(),
        bitrate: bit,
        delay: delay,
        pn: 'nhm'
      }
      if (!params.rid) return
      this.axios.post('/ccp/tvucc-device/receiver/setBitrateAndDelay', params)
        .then(res => {
          if (res.data.errorCode == '0x0') {
            if (JSON.parse(res.data.result).errorCode == '0x0') {
              $('.edit_box').hide()
              this.$message({
                type: 'success',
                message: this.$t('lang.success')
              })
            }
          } else {
            Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
          }
        }).catch(() => {})
    },
    // 更改device列表
    initDataList (val) {
      if (this.$route.path.includes('/nhm')) {
        this.stopupdateParams()
        this.initCurrentInfoSocket = true
      }
      let list = JSON.parse(JSON.stringify(val))
      const devicePeerIdArr = []
      for (let item of list) {
        item.thumbnailObj = ''
        item.source = item.name
        item.destination = item.livePeerName
        item.liveBitrate = ''
        item.bitrate = ''
        item.latency = ''
        item.format = ''
        item.battery = null
        // item.battery = showBattery(item.batteryInfo, item.platform)
        item.btnStatus = item.status == 2 ? 'stop' : 'goLive' // 按钮状态
        item.location = ''
        item.typeVal = this.$options.filters.SETTINGTYPE(item)
        devicePeerIdArr.push(item.peerId)
      }
      this.deviceList = list
      this.getLastLiveDevice(devicePeerIdArr)
      this.getOnlineData()
      this.getLocationData(0)
    },
    getLastLiveDevice (peerArr) {
      this.axios.post('/ccp/tvucc-device/device/getDeviceLastLivePidMsg', peerArr, {
        contentType: 'application/json;charset=UTF-8',
        isCancelToken: true
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.length) {
            const resultArr = res.data.result
            resultArr.forEach(m => {
              const index = this.deviceList.findIndex(v => v.peerId == m.peerId)
              if (index >= 0 && m.livePid) {
                this.deviceList[index].lastLiveName = m.peerName
              }
            })
          }
        }
      }).catch(() => {})
    },
    getBookmarkName (userBookmarkDevice) {
      let selectBookmark = []
      if (userBookmarkDevice) {
        let bookmarkList = []
        for (let key in userBookmarkDevice) {
          bookmarkList.push(userBookmarkDevice[key])
        }
        bookmarkList.map(v => {
          let arr = [`${v[0].bookmarkName}`]
          if (v[0].parent.length) {
            this.getParent(v[0].parent[0], arr)
          }
          selectBookmark.push(arr.join(','))
        })
      }
      return selectBookmark.join(',') || '- - -'
    },
    // 获取父级id和name
    getParent (parent, arr) {
      arr.unshift(`${parent.bookmarkName}`)
      if (parent.parent.length) {
        this.getParent(parent.parent[0], arr)
      }
    },
    // 打开bookmark box
    operationBookmarkBox (flag, userBookmarkDevice, peerId) {
      if (flag) {
        this.updateBookmarkObj.userBookmarkDevice = userBookmarkDevice
        this.updateBookmarkObj.peerId = peerId
      }
      this.isShow.bookmark = flag
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableShowBookmark', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableShowBookmark', this.$route.path)
      }
    },
    // 列拖拽
    columnDrop () {
      const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
      this.sortable = Sortable.create(wrapperTr, {
        animation: 150,
        delay: 0,
        filter: 'th.forbid_drag', // 过滤器，不需要进行拖动的元素
        onEnd: evt => {
          if (evt.oldIndex <= 3 || evt.newIndex <= 3) { // 禁止移入固定列
            if (!document.querySelector('.el-table__header-wrapper .has-gutter>tr')) return
            const moveEle = document.querySelectorAll('.el-table__header-wrapper .has-gutter th')[evt.newIndex]
            document.querySelector('.el-table__header-wrapper .has-gutter>tr').removeChild(moveEle)
            document.querySelector('.el-table__header-wrapper .has-gutter>tr').insertBefore(moveEle, document.querySelectorAll('.el-table__header-wrapper .has-gutter th')[evt.oldIndex])
            return
          }
          const oldItem = this.dropCol[evt.oldIndex - 1]
          this.dropCol.splice(evt.oldIndex - 1, 1)
          this.dropCol.splice(evt.newIndex - 1, 0, oldItem)
          // 改变title数组
          const data = this.lastTitleArr.splice(evt.oldIndex - 1, 1)
          this.lastTitleArr.splice(evt.newIndex - 1, 0, data[0])
          if (this.$route.path.indexOf('nhm') > -1) {
            newHomeAnalyze('newHomeTableDragColumn', this.$route.path)
          } else {
            mySourceAnalyze('mySourceTableDragColumn', this.$route.path)
          }
        }
      })
    },
    // device status
    getStatusClass (item) {
      switch (Number(item.status)) {
        case 1:
        case 3:
          return 'green'
        case 2:
          return 'red'
        default:
          return ''
      }
    },
    // 关闭弹窗
    closeVideoBox () {
      this.showVideoControl = false
      Bus.$emit('openListWebsocket', 'sourceList')
      Bus.$emit('openRVideoDetail', this.showVideoControl)
      this.currentDevice = null
      // 关闭弹窗后重新获取bitrate delay和format
      this.createMsgWebsocket()
      this.updateParams()
      this.initCurrentInfoSocket = false
    },
    // 展开详情框
    expandDetail (row, column, event) {
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableShowVideo', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableShowVideo', this.$route.path)
      }
      let quality = document.querySelector('#quality')
      if (quality) quality.id = 'quality-last'
      if (this.page == 'nhm') {
        this.currentDevice = row
        this.currentSocketData = handleSource(row.peerId, row.livePeerId, row.status == '2', row)
        this.showVideoControl = true
        if (this.$createWebsocket.pageRWebsocket) {
          this.$createWebsocket.pageRWebsocket('stop', 'receiverData') // 停止websocket
        }
        this.stopupdateParams()
        return
      }
      this.currentDevice = null
      if (this.expands[0] == row.peerId) {
        this.expands = []
        const dataObj = {
          rid: '',
          sourceItem: {},
          isDown: false
        }
        Bus.$emit('showReceiverInfo', dataObj)
        return
      }
      this.expands = []
      if (row.status == 0) return
      this.expands.push(row.peerId)
    },
    // 获取delay
    getDelay (value, setFlag) {
      if (!setFlag) {
        const val = this.$t('lang.Dynamic')
        return val
      }
      return `${value} s`
    },
    // 获取是否重新选择了R
    changeHadSelectDevice (val, peerId) {
      this.isSelectNewR[peerId] = val
      if (this.$route.path.indexOf('nhm') > -1) {
        newHomeAnalyze('newHomeTableSelectR', this.$route.path)
      } else {
        mySourceAnalyze('mySourceTableSelectR', this.$route.path)
      }
    },
    getSelectInfo (data) { // 获取SelectSourceR.vue组件的参数
      this.changeRArr[data.rid] = data.deviceList // 监听SelectSourceR.vue组件传递过来的参数
      const currentSelectRid = data.deviceList.split(',')[1]
      const gridLivePeerId = this.data.allSourceData[data.rid] && this.data.allSourceData[data.rid].gridLivePeerId
      if (gridLivePeerId != null) {
        if (gridLivePeerId.includes(currentSelectRid)) {
          this.deviceDetailId = currentSelectRid
        }
      }
      this.hasStopSelectR = false
    },
    stopRState () {
      this.hasStopSelectR = true
    },
    // 获取地理位置信息
    getLocationData (time) {
      this.lang = window.localStorage.getItem('lang')
      let mapType = ''
      if (this.lang == 'zh') {
        mapType = 'baidu'
      }
      const tPeerIdObjs = this.deviceList.filter(v => {
        return v.type.toLowerCase() == 't' || v.type.toLowerCase() == 'x'
      })
      let tPeerIds = []
      tPeerIdObjs.map(v => {
        let peerId = ''
        if (v.type.toLowerCase() == 'x') {
          peerId = v.peerId.slice(0, 16)
        } else {
          peerId = v.peerId
        }
        tPeerIds.push(peerId)
      })
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'getLocationByIps_hm') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
      }
      this.pageRequest.send({
        message: {
          type: 'getLocationByIps',
          module: 'getLocationByIps_hm',
          data: { peerIds: tPeerIds.join(','), peerIps: '', mapType: mapType }
        },
        time: time,
        key: 'getLocationByIps_hm',
        success: res => {
          const data = res.result
          if (data.errorCode == '0x0') {
            if (data.result) {
              try {
                if (JSON.parse(data.result) && JSON.parse(data.result).data && JSON.parse(data.result).data.length > 0) {
                  const locationArr = JSON.parse(data.result).data
                  this.locationInfo = []
                  locationArr && locationArr.map(v => {
                    if (v.address && typeof v.address != 'string') {
                      let address = ''
                      v.address.map((item, idx) => {
                        if (idx != v.address.length - 1) {
                          address += (item.long_name + ' , ')
                        } else {
                          address += item.long_name
                        }
                      })
                      v.address = address
                    }
                    this.locationInfo[v.peerId.toLowerCase()] = v
                  })
                }
              } catch (error) {
              }
            }
          }
          // let newParam = []
          this.$createWebsocket.pageRequest('stop', 'getLocationByIps_hm')
          this.getLocationData(60000)
        }
      })
    },
    // 获取table记录
    async getBehavior () {
      const params = {
        pn: location.hash.split('?')[0].slice(2),
        remark: this.StateObj.common.baseUserInfo.id,
        serverName: 'ccp'
      }
      await this.axios.get('/ccp/tvucc-user/userBehavior/get', { params })
        .then(res => {
          if (res.data.errorCode == '0x0') {
            if (!res.data.result || !res.data.result[this.StateObj.common.baseUserInfo.id]) {
              this.lastTitleArr = JSON.parse(JSON.stringify(this.titleArr))
              return
            }
            const result = JSON.parse(res.data.result[this.StateObj.common.baseUserInfo.id])
            const selectResult = result.select
            for (const key in this.check) { // 多选全部置为false
              this.check[key] = false
            }
            this.titleArr = []
            this.dropCol = []
            for (const key in selectResult) {
              if (Object.prototype.hasOwnProperty.call(this.check, key)) this.check[key] = true // 多选选中的为true
              this.titleArr.push({ name: selectResult[key].name, width: selectResult[key].width })
              this.dropCol.push(selectResult[key].name)
            }
            this.lastTitleArr = JSON.parse(JSON.stringify(this.titleArr))
            // 没有被选中的列
            this.removeTitles = result.unSelect
          }
        }).catch(() => {})
    },
    // 保存table记录
    async setBehavior (newWidth, oldWidth, column) {
      // let className
      let isRefresh = false
      if (newWidth) {
        if (this.$route.path.indexOf('nhm') > -1) { // 谷歌记录
          newHomeAnalyze('newHomeTableColumnStretch', this.$route.path)
        } else {
          mySourceAnalyze('mySourceTableColumnStretch', this.$route.path)
        }
        this.titleArr = JSON.parse(JSON.stringify(this.lastTitleArr))
        if (oldWidth - newWidth > 0) { // 当前列宽减少了
          if (!column.className.split(' ').includes(this.titleArr[this.titleArr.length - 1].name)) { // 当前列宽(非最后一列)减少的给最后一列加上去
            this.titleArr[this.titleArr.length - 1].width = Number(this.titleArr[this.titleArr.length - 1].width) + oldWidth - newWidth
          } else { // 最后一列列宽减少的给倒数第二列加上去
            this.titleArr[this.titleArr.length - 2].width = Number(this.titleArr[this.titleArr.length - 2].width) + oldWidth - newWidth
          }
        }
        // 更改调整后的列宽记录
        for (let i = 0; i < this.titleArr.length; i++) {
          if (column.className.split(' ').includes(this.titleArr[i].name)) {
            isRefresh = newWidth < 70
            this.titleArr[i].width = newWidth < 70 ? 70 : newWidth
          }
        }
        if (column.className.split(' ')[0] == 'thumbnailObj') {
          isRefresh = newWidth < 150
          this.titleArr[0].width = newWidth < 150 ? 150 : newWidth
        }
      }
      this.lastTitleArr = JSON.parse(JSON.stringify(this.titleArr))
      let selectObj = {}
      for (const value of this.titleArr) {
        selectObj[value.name] = value
      }
      const record = {
        select: selectObj,
        unSelect: this.removeTitles
      }
      const params = {
        pn: location.hash.split('?')[0].slice(2),
        remark: this.StateObj.common.baseUserInfo.id,
        content: JSON.stringify(record)
        // content: ''
      }
      this.axios.post('/ccp/tvucc-user/userBehavior/set', params)
        .then(res => {
          if (res.data.errorCode == '0x0') {
            return true
          }
          return false
        })
        .catch(() => {
          return false
        })
      // 改变view层
      if (isRefresh) {
        const titleArr = JSON.parse(JSON.stringify(this.titleArr))
        this.titleArr = []
        this.$nextTick(() => {
          this.titleArr = titleArr
        })
      }
      this.sortable.destroy()
      this.isBoxShadow()
      this.columnDrop()
    },
    // 判断是否有滚动条，如果有右侧固定列添加阴影效果
    isBoxShadow () {
      setTimeout(() => {
        this.hasShadow = !$('.table_box .el-table__body-wrapper').hasClass('is-scrolling-none')
      }, 100)
    },
    updateDevice (b) {
      if (b.operationType == 101) {
        this.deviceList.map((v) => {
          if (v.livePeerId == b.rid) {
            // if (v.status != '2') v.liveBitrate = JSON.parse(b.data).CurrentSetting.Bitrate + ' Kbps'
            v.BitrateNum = JSON.parse(b.data).CurrentSetting.Bitrate
            v.livelatency = JSON.parse(b.data).CurrentSetting.Delay
            v.StrVideoMode = JSON.parse(b.data).CurrentSetting.StrVideoMode
            v.CurrentTCanSetVideoMode = JSON.parse(b.data).CurrentTCanSetVideoMode
            v.CurrentTCanSetDelay = JSON.parse(b.data).CurrentTCanSetDelay
            v.CurrentTCanSetDelayConfigurable = JSON.parse(b.data).CurrentTCanSetDelayConfigurable
            v.CurrentTCanSetBitrate = JSON.parse(b.data).CurrentTCanSetBitrate
          }
        })
      }
      if (b.operationType == 102) {
        this.deviceList.map((v) => {
          if (v.livePeerId == b.rid) {
            if (v.format != JSON.parse(b.data).CurrentTFormat.ReadableName) {
              v.ReadableName = JSON.parse(b.data).CurrentTFormat.ReadableName
            }
            v.CurrentTPackBatteryRaw = JSON.parse(b.data).CurrentTPackBatteryRaw
          }
        })
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // this.unbindBookmarkEvent()
    Bus.$off('openRVideoDetail')
    this.removeBitrEvent()
    if (this.paramTimer) clearInterval(this.paramTimer)
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', ['currentRInfo', 'setInfo', 'detailInfo']) // 停止websocket
    }
    next()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
    if (this.$createWebsocket.pageRequest.detailInfo) {
      this.$createWebsocket.pageRequest('stop', 'detailInfo') // 停止websocket taskInfo_R_W这个可以自定义 发送什么返回什么
    }
    if (this.paramTimer) clearInterval(this.paramTimer)
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-table .el-table__header-wrapper .has-gutter {
  .cell {
    max-height: 46px;
  }

  tr,
  th {
    background: #252525;
    border-bottom: 2px solid #217536;
  }
}

::v-deep ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}

::v-deep ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgba(144, 147, 153, 0.3);
  opacity: 0.7;
}

.device_list {
  .operation_col.shadow {
    box-shadow: -7px 0 18px #222;
  }

  .operation_col {
    width: 123px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    background: #252525;
    padding-right: 15px;

    .el-icon-more {
      transform: rotate(-90deg);
      color: #666;
    }

    ::v-deep .el-dropdown {
      float: right;
    }

    > div:first-child {
      height: 25px;
      line-height: 20px;
      border-bottom: 2px solid #217536;
      color: rgb(144, 147, 153);
      font-weight: 700;
      padding-left: 15px;
    }

    .device_btn {
      height: 87px;
      line-height: 87px;
      border-bottom: 1px solid #333;
      padding-left: 15px;
    }
  }

  // ::v-deep .table_box {
  //     width: calc(100% - 123px);
  // }
  // ::v-deep .table_box .el-table .el-table__body-wrapper {
  //     padding-right: 123px;
  // }
  ::v-deep .el-table__header-wrapper .has-gutter .cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ::v-deep .not_allow {
    cursor: not-allowed;
  }

  // margin-right: 20px;
  ::v-deep .cell {
    .bookmark,
    .battery,
    .source,
    .typeVal,
    .format,
    .location {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ::v-deep .el-table__header-wrapper {
    // tr th:nth-last-child(2) {
    //     border-right: none;
    // }
  }

  ::v-deep .el-table--border th,
  ::v-deep .el-table th {
    border-color: #333;
  }

  ::v-deep .el-table--border thead th {
    border-width: 0;
  }

  ::v-deep tr > th:first-child {
    border-right: none;
  }

  ::v-deep tr > td:first-child {
    .el-table__expand-icon {
      display: none;
    }
  }

  ::v-deep .el-table--border thead:hover th {
    border-right-width: 2px;
  }

  ::v-deep .el-table--border td,
  ::v-deep .el-table .el-table-row td,
  ::v-deep .el-table tr td {
    border-left: none;
    border-right: none;
    background: #252525;
  }

  ::v-deep .el-table--group,
  ::v-deep .el-table--border {
    border: none;
  }

  ::v-deep .el-table--group::after,
  ::v-deep .el-table--border::after,
  ::v-deep .el-table::before {
    background: transparent;
  }

  ::v-deep .el-table .el-table__row td .cell,
  ::v-deep .el-table .el-table__row th .cell {
    height: 86px;
    line-height: 86px;
    overflow: visible;

    > span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
    }
  }

  ::v-deep .bitrate_box {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  ::v-deep tr > td:first-child .el-icon-arrow-right {
    visibility: hidden;
  }

  ::v-deep .device_btn {
    button {
      width: 75px!important;
      height: 32px;
      padding: 0;
      line-height: 32px;
      text-align: center;
      max-width: 90px;
    }

    .is-disabled.gray {
      background: #444;
    }

    .takeButtonModule {
      float: none !important;
    }
  }

  ::v-deep .expend_box {
    height: 513px;
    border-bottom: 1px solid #333;
  }

  ::v-deep .title_icon_more {
    div {
      padding: 0;
    }
  }

  ::v-deep .el-select .el-input__inner {
    border-radius: 8px;
    border: 1px solid rgba(63, 62, 62, 1);
    background: transparent;
  }

  .el-button--danger {
    background: #eb4040;
    border-color: #eb4040;
  }

  .title_select {
    .el-icon-more {
      transform: rotate(-90deg);
      color: #666;
    }

    ::v-deep .el-dropdown {
      float: right;
    }
  }

  .thumbnail_img {
    width: 114px;
    height: 64px;
    padding: 0;
    border: none;
    margin: 12px 0;
    background: #252525;
  }

  .status.green:before {
    background: #33ab4f;
  }

  .status.red:before {
    background: #eb4040;
  }

  .status:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: #333;
    margin-right: 5px;
  }

  ::v-deep .el-table th,
  ::v-deep .el-table tr .el-table__expanded-cell {
    background: #252525 !important;
    padding: 0;
  }

  // ::v-deep .el-table__body-wrapper {
  //     overflow: auto;
  // }
  .bookmark_name {
    text-decoration: underline;
  }

  .video_detail:before {
    content: '\e78f';
    position: absolute;
    top: -25px;
    left: 50%;
  }

  .video_detail.show {
    position: relative;
    z-index: 100;
    background: #111;
  }

  .video_detail {
    font-family: element-icons !important;
    background: rgba(1, 1, 1, 0.5);
    height: 480px;
    width: 1882px;
    padding: 0 20px;
    margin-top: 15px;
    // margin-top: -100px;
    margin-bottom: 18px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.56);
    border-radius: 8px;
    color: rgba(1, 1, 1, 0.5);
    font-size: 28px;
    transition: all 0.5s;

    ::v-deep .infoBox {
      font-size: 14px;
      color: #fff;
    }

    ::v-deep .voiceModule {
      height: 423px;
    }

    ::v-deep .vidoeModule {
      margin-right: 25px;
    }

    ::v-deep .delayBitrateModule {
      width: 20%;
      margin-left: 0;
    }

    ::v-deep .DetailModule {
      width: auto;
      margin-left: 0;

      .live_info {
        width: 4rem !important;
      }
    }
  }

  ::v-deep .column_r .cell {
    overflow: visible;
  }

  .r_box {
    ::v-deep .el-input {
      height: 32px;
      line-height: 32px;
    }

    ::v-deep .arrow-icon {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  ::v-deep .down_box.right_side .down_triangle:after {
    content: '\e791';
  }

  ::v-deep .down_box.top_side .down_triangle:after {
    content: '\e78f';
  }

  ::v-deep .down_box.right_side .edit_box {
    left: 74px;
    top: 6px;
  }

  ::v-deep .down_box.top_side .edit_box {
    left: 0;
    top: -50px;
  }

  ::v-deep .down_box {
    overflow: visible !important;

    .down_triangle {
      font-family: element-icons !important;

      span {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      }

      cursor: pointer;
    }

    .down_triangle:after {
      content: '\e790';
    }

    .edit_box {
      position: absolute;
      left: 0;
      top: 60px;
      width: 160px;
      height: 71px;
      display: none;
      z-index: 200;
      background: rgba(27, 27, 27, 1);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 4px;

      .seleType {
        line-height: 18px;
        margin: 5px 10px;

        .cbr,
        .vbr {
          cursor: pointer;
          margin-right: 5px;
        }

        .dmc,
        .fixd {
          cursor: pointer;
          margin-right: 5px;
        }

        .greenCol {
          color: #33ab4f;
        }
      }

      .edit_bit {
        width: 80px;
        height: 26px;
        border-radius: 2px;
        border: 1px solid rgba(102, 102, 102, 1);
        background: none;
        outline: none;
      }

      .edit_bit:focus {
        border: 1px solid #252525;
      }

      .edit_delay {
        width: 80px;
        height: 26px;
        border-radius: 2px;
        border: 1px solid rgba(102, 102, 102, 1);
        background: none;
        outline: none;
      }

      .edit_delay:focus {
        border: 1px solid #252525;
      }
    }

    p {
      float: left;
    }
  }

  .greenInfo {
    color: #33ab4f;
  }
}

.battery1-box,
.battery2-box {
  width: 35%;
  text-indent: 12px;
  /*padding: 0 2%;*/
  padding: 0;
  margin-top: 20px;
  line-height: 50px;
}
.battery1-box {
  width: 100%;
}

.battery-moudel {
  border: 1px solid #5cb85c;
  height: 14px;
  width: 70%;
  padding: 2px 2px;
  position: relative;
}

.battery1-box .battery-moudel {
  margin-left: 3px;
  width: 20px;
  margin-top: 17px;
}

.battery-dot {
  position: absolute;
  top: 3px;
  right: -5px;
  border: 1px solid #5cb85c;
  border-left: 0px;
  height: 5px;
  width: 4px;
}

.battery1-value,
.battery2-value {
  display: block;
  background: #5cb85c;
  height: 8px;
  width: 70%;
}

.batterySpan {
  /*display: inline-block;*/
  width: 45px;
  margin-left: -6px;
  padding: 0;
  font-size: 14px;
}
</style>
<style lang="less">
.devicelist_title_list {
  width: 176px;
  height: 266px;
  font-size: 14px;
  color: #dbdbdb;
  background: rgba(27, 27, 27, 1);
  border-radius: 4px;
  border: none;

  li {
    height: 30px;
    line-height: 30px;
    padding: 0;

    .el-checkbox {
      width: 100%;
      left: 0;
      padding-left: 32px;
      padding-right: 5px;
      margin: 0;
    }
  }
}
</style>
